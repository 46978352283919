<template>
  <div class="entrance">
    <div class="tip">登录入口</div>
    <div class="entrance-box" v-for="item in loginImg" :key="item.id">
      <div class="n-entrance-box" @click="login(item.id)">
        <div><img :src="item.url" style="width: 30px;height:30px;"></div>
        <div style="color: #fff;">{{item.title}}</div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" title="Tips" width="1139" top="20vh" @close='close'>
      <div style="border-radius: 5px;background: #fff;display: flex;justify-content: space-between;">
        <div style="width:736px;"><img src="./../static/login-img.png" alt="" style="object-fit:cover;width: 100%;height: 100%;"></div>
        <div class="login-form">
          <div class="login-ico" @click="CloseDialog">
            <el-icon>
              <Close style="font-size:30px;" />
            </el-icon>
          </div>
          <div class="login-title">欢迎登录</div>
          <div class="login-method">
            <div class="flex" v-for="item in SignInMethod" :key="item.id">
              <div style="cursor: pointer;" :class="{'active':item.id==activeId}" @click="activeIdMed(item.id)">{{item.title}}</div>
              <div v-if="item.id<3" style="margin: 0 15px;">
                <el-icon>
                  <Switch />
                </el-icon>
              </div>
            </div>
          </div>
          <div>
            <UserLogin v-if="activeId==1" />
            <PhoneLogin v-if="activeId==2" />
            <CaLogin v-if="activeId==3" />
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import UserLogin from "@/components/login/UserLogin.vue";
import PhoneLogin from "@/components/login/PhoneLogin.vue";
import CaLogin from "@/components/login/CaLogin.vue";
import { ref, onMounted } from 'vue';
import { RegulatoryEnd, CoverageSide } from '@/api/index.js'
let RegulatoryEndList = ref()
let CoverageSideList = ref()
let loginImg = ref([
  { id: 1, title: "监管单位", url: require('./../static/login1.png') },
  // { id: 2, title: "承保端", url: require('../static/login2.png') },
  { id: 3, title: "施工单位", url: require('../static/login3.png') },
  { id: 4, title: "咨询电话", url: require('../static/login4.png') }
])
let SignInMethod = ref([
  { id: 1, title: '账号登录' },
  { id: 2, title: '手机号登录' },
  { id: 3, title: 'CA登录' }
])
let dialogVisible = ref(false)
let activeId = ref(1)
let close = () => {
  dialogVisible.value = false
  localStorage.removeItem('Dialog')
  let box = document.querySelector('.entrance')
  box.style.width = '0'
  activeId.value = 3
}
let activeIdMed = (id) => {
  activeId.value = id
}
let login = (val) => {
  let box = document.querySelector('.entrance')
  if (val == 3) {
    box.style.width = '100%'
    dialogVisible.value = true
    activeId.value = 1
  } else if (val == 1) {
    window.open(RegulatoryEndList.value + '/login?type=1', '_blank')
  } else if (val == 2) {
    window.open(CoverageSideList.value + '/login?type=2', '_blank')
  }
}
let CloseDialog = () => {
  dialogVisible.value = false
  let box = document.querySelector('.entrance')
  box.style.width = '0'
  localStorage.removeItem('Dialog')
  activeId.value = 3
}
onMounted(async () => {
  let box = document.querySelector('.entrance')
  let user = localStorage.getItem('tokenuser')
  let Dialog = localStorage.getItem('Dialog')
  if (user === null && Dialog) {
    dialogVisible.value = true
    box.style.width = '100%'
  } else {
    dialogVisible.value = false
    box.style.width = '0'
  }
  await RegulatoryEnd().then(res => {
    RegulatoryEndList.value = res.data.paramValue
  })
  await CoverageSide().then(res => {
    CoverageSideList.value = res.data.paramValue
  })
})
</script>
<style scoped>
.active {
  color: rgba(31, 112, 247, 1);
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .el-dialog {
  padding: 0;
}
::v-deep .el-dialog__header {
  display: none;
}
.login-ico {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.login-form {
  width: 403px;
  height: 100%;
  padding: 0 52px;
  box-sizing: border-box;
  background: #fff;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.login-title {
  font-size: 30px;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 20px;
  margin-top: 75px;
}
.login-method {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  display: flex;
  margin-bottom: 44px;
}
.entrance {
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}
.tip {
  width: 64px;
  height: 56px;
  opacity: 1;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 28px;
  color: rgba(31, 112, 247, 1);
}
.entrance-box {
  cursor: pointer;
  margin-bottom: 5px;
  padding: 5px;
  box-sizing: border-box;
  background: rgba(59, 133, 243, 0.1);
}
.tip {
  margin-bottom: 5px;
}
.n-entrance-box {
  width: 74px;
  height: 74px;
  border-radius: 4px;
  background: linear-gradient(
    135deg,
    rgba(67, 200, 238, 1) 0%,
    rgba(106, 120, 252, 1) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>